angular.module('main')
    .directive('emissionAerial', function() {
        return {
            scope: {
                indicator: '=',
                id: '=',
                openModalEvidences: '=',
                openModalEvidencesZip: '=',
                month: '='
            },
            restrict: 'E',
            replace: true,
            templateUrl: 'emission-aerial.tpl.html',
            controller: controller,
            controllerAs: 'vm',
            bindToController: true
        };

        function controller($uibModal,companyCertificationService,companyCertification) {
            var vm = this;
            vm.save = save;
            vm.updateEquipmentFilter = updateEquipmentFilter;
            vm.addEmissionDirect = addEmissionDirect;
            vm.specificSummaryf = specificSummary;
            vm.showConfirmation = showConfirmation;
            vm.showEquiomentList = showEquiomentList;
            vm.selectedMonth = vm.month[0];
            vm.totalPassengerNumber = 0;
              
             
              
            if (typeof vm.indicator.answers === 'undefined') {
                loadAnswers();    
                loadEquipmentFilter();
            }else{
                loadAnswers();
                loadEquipmentFilter(); 
            }
            function loadAnswers() {
                companyCertificationService.loadIndicatorAnswersCalculator(vm.id, vm.indicator.id,'direct-emission',1,companyCertification.year)
                .then(success)
                .catch(error);
                function success(response) {
                  vm.indicator.answers = response.data;
                  loadEmissionActivities();
                  emissionSummary();
                 
                }

                function error(error) {
                    console.log(error);
                }  
            }


            function loadEquipmentFilter() {
                companyCertificationService.loadEquipmentFilter(vm.id,'calcu_013',1)
                .then(success)
                .catch(error);
                function success(response) {
                  vm.equipments = response.data;
                }

                function error(error) {
                    console.log(error);
                }  
            }

            function save() {
               
                data = { 
                   "indicator_id": vm.indicator.id ,
                   "emission_direct_type": 'aereos',
                   "emission": 'significant',
                   "company_certification_id": vm.id,
                   "answers": vm.indicator.answers};
                companyCertificationService.saveIndicatorAnswersCalculator( data ,'direct-emission')
                .then(success)
                .catch(error);
                function success(response) {
                    loadAnswers();
                }

                function error(error) {
                    console.log(error);
                }               
            }

            function getGasEquivalents(year) {
                var equivalents;
              
                if (year >= 2024) {
                  equivalents = {
                    CO2: 1,
                    CH4: 28,
                    N2O: 265
                  };
                } else {
                  equivalents = {
                    CO2: 1,
                    CH4: 21,
                    N2O: 310
                  };
                }
              
                return equivalents;
              }

            function addEmissionDirect() {

                data = { 
                    "indicator_id": vm.indicator.id ,
                    "emission_direct_type": 'aereos',
                    "emission": 'significant',
                    "company_certification_id": vm.id};

                companyCertificationService.addResource( data,'direct-emission' )
                .then(success)
                .catch(error);
                function success(response) {
                    loadAnswers();
                    loadEquipmentFilter(); 
                }    
                function error(error) {
                    console.log(error);
                } 
              }

              function specificSummary() {
                vm.specificSummary = [];

                const volatil = [];

                for (var index = 0; index < vm.indicator.answers.length; index++) {
                    if (typeof vm.indicator.answers[index].equipment !== 'undefined') {
                        if (typeof vm.indicator.answers[index].equipment.emission_activity !== 'undefined') {
                            if (vm.indicator.answers[index].equipment.emission_activity.id == vm.emissionActivitiesSelect.id) {
                                volatil.push(vm.indicator.answers[index]);
                            }
                        }
                    }    
                }

                var equipos = [];
                var total =0;

                var c02 = 0;
                var ch4 = 0;
                var n20 = 0;
            
                var gasE = getGasEquivalents( companyCertification.year)
                
                vm.specificSummary['potencial_c02'] =  gasE.CO2;
                vm.specificSummary['potencial_ch4'] = gasE.CH4;
                vm.specificSummary['potencial_n20'] = gasE.N2O;

                for (var index = 0; index < volatil.length; index++) {
                     equipos.push(volatil[index].equipment.name); 
                     var totalEquipoConsumo =  totalConsumo(volatil[index].month_emission);                            
                     total += totalEquipoConsumo;
                     vm.specificSummary['factor_c02'] = volatil[index].equipment.emission_activity.emission_factor[0].co2;
                     vm.specificSummary['factor_ch4'] = 0;
                     vm.specificSummary['factor_n20'] = 0;
                     c02 += (totalEquipoConsumo * volatil[index].equipment.emission_activity.emission_factor[0].co2 * vm.specificSummary['potencial_c02']);
               }

               vm.specificSummary['equipos'] = equipos;
               vm.specificSummary['totalConsumo'] = total;
               vm.emissionSummary['totalPassengerNumber'] =  vm.totalPassengerNumber ;
               vm.specificSummary['c02'] = c02.toFixed(3);
               vm.specificSummary['ch4'] = 0;
               vm.specificSummary['n20'] = 0;
               vm.specificSummary['totalc02e'] =  ((c02) / 1000).toFixed(3);
               
              }
           
              function emissionSummary() {
                vm.emissionSummary = [];

                const volatil = [];
              
                var total =0;
                var equipos ='';
                var c02 = 0;
                for (var index = 0; index < vm.indicator.answers.length; index++) {
                    if (typeof vm.indicator.answers[index].equipment !== 'undefined') {
                        if (typeof vm.indicator.answers[index].equipment.name !== 'undefined') {
                          
                            equipos += vm.indicator.answers[index].equipment.name+' ,'; 
                           
                            var totalEquipoConsumo =  totalConsumo( vm.indicator.answers[index].month_emission);
                            
                         
                             total += totalEquipoConsumo;
                             c02 += (totalEquipoConsumo * vm.indicator.answers[index].equipment.emission_activity.emission_factor[0].co2 * 1);
                            
                        }
                     }
                }

               vm.emissionSummary['c02'] = c02.toFixed(3) ;
               vm.emissionSummary['totalKM'] = total.toFixed(3) ;
               vm.emissionSummary['ch4'] = 0;
               vm.emissionSummary['n20'] = 0;
               vm.emissionSummary['equipos'] = equipos ;
               vm.emissionSummary['totalPassengerNumber'] =  vm.totalPassengerNumber ;
               vm.emissionSummary['totalSummary'] = ((c02) / 1000).toFixed(3);

              }
            
              function totalConsumo(emission) {
                var months = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'];

                // Inicializa las variables para el total
                var total = 0;
                vm.totalPassengerNumber = 0;
                
                months.forEach(function(month) {
                    var consumo = parseFloat(emission[month].consumo, 10);
                    var passengerNumber = parseFloat(emission[month].passenger_number, 10);
                  
                    total += (typeof emission[month].consumo === 'undefined' || isNaN(consumo)) ? 0 : consumo;
                    vm.totalPassengerNumber += (typeof emission[month].passenger_number === 'undefined' || isNaN(passengerNumber)) ? 0 : passengerNumber;
                  });


                return total;
              }

              function loadEmissionActivities() {
                companyCertificationService.loadEmissionActivities()
                .then(success)
                .catch(error);
                function success(response) {
                  vm.emissionActivities = response.data;
                  vm.emissionActivitiesSelect = response.data[0];
                }
                function error(error) {
                    console.log(error);
                }  
              }
              function updateEquipmentFilter(oldE,newE,key) {
                if ( newE != null) {
                    for (var index = 0; index <   vm.equipments.length; index++) {
                        if(vm.equipments[index].id == oldE.id){
                         vm.equipments[index].notAnOption = 0;
                        }
                        if(vm.equipments[index].id == newE.id){
                         vm.equipments[index].notAnOption = 1;
                        }
                     }
                }else{
                    vm.indicator.answers[key].equipment =  oldE;
                }
              }

             function showConfirmation(answer, key) {
                $uibModal.open({
                    animation: true,
                    component: 'deleteAnswerEmission',
                    resolve: {
                        emissionAnswer: function () {
                            return answer;
                        },
                        key: function () {
                            return key;
                        },
                        loadAnswers: function () {
                            return loadAnswers;
                        }
                    }
                });
              }

              function showEquiomentList(answers, key) {
                $uibModal.open({
                    animation: true,
                    component: 'listEquipment',
                    resolve: {
                        equipment: function () {
                            return answers;
                        },
                        key: function () {
                            return key;
                        },
                    }
                });
              }


        }
    });