(function () {
    'use strict';

    angular
            .module('main')
            .service('certificationService', certificationService);

    function certificationService($q, $http) {

        return {
            findOneById: findOneById,
            findSubCertifications: findSubCertifications,
            saveIndicatorFilter: saveIndicatorFilter,
            findIndicatorFilters: findIndicatorFilters,
            removeIndicatorFilter: removeIndicatorFilter,
            saveIndicatorScheme: saveIndicatorScheme,
            findIndicatorSchemes: findIndicatorSchemes,
            removeIndicatorScheme: removeIndicatorScheme
        };

        function findOneById(id) {
            var deferred = $q.defer();

            $http.get('/api/certifications/' + id, {headers: {skipAuthorization: true}}).then(success, error);

            function success(response) {
                console.log(response);
                
                deferred.resolve(response.data);
            }

            function error(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function findSubCertifications(id) {
            var deferred = $q.defer();

            $http.get('/api/certifications/' + id + '/sub-certifications', {
                headers: {skipAuthorization: true}
            }).then(success, error);

            function success(response) {
                deferred.resolve(response.data);
            }

            function error(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function saveIndicatorFilter(id, model) {
            var deferred = $q.defer();

            $http.post('/api/certifications/' + id + '/indicator-filters', model, {
                headers: {skipAuthorization: true}
            }).then(success, error);

            function success(response) {
                deferred.resolve(response.data);
            }

            function error(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function findIndicatorFilters(id) {
            var deferred = $q.defer();

            $http.get('/api/certifications/' + id + '/indicator-filters', {
                headers: {skipAuthorization: true}
            }).then(success, error);

            function success(response) {
                deferred.resolve(response.data);
            }

            function error(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function removeIndicatorFilter(certificationId, filterId) {
            var deferred = $q.defer();

            $http.delete('/api/certifications/' + certificationId + '/indicator-filters/' + filterId, {
                headers: {skipAuthorization: true}
            }).then(success, error);

            function success(response) {
                deferred.resolve(response.data);
            }

            function error(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function saveIndicatorScheme(id, model) {
            var deferred = $q.defer();

            $http.post('/api/certifications/' + id + '/indicator-schemes', model, {
                headers: {skipAuthorization: true}
            }).then(success, error);

            function success(response) {
                deferred.resolve(response.data);
            }

            function error(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function findIndicatorSchemes(id, container) {
            var deferred = $q.defer();

            $http.get('/api/certifications/' + id + '/indicator-schemes?container=' + container, {headers: {skipAuthorization: true}}).then(success, error);

            function success(response) {
                deferred.resolve(response.data);
            }

            function error(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function removeIndicatorScheme(certificationId, filterId) {
            var deferred = $q.defer();

            $http.delete('/api/certifications/' + certificationId + '/indicator-schemes/' + filterId, {
                headers: {skipAuthorization: true}
            }).then(success, error);

            function success(response) {
                deferred.resolve(response.data);
            }

            function error(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }
    }
})();